<template>
    <div>
        <div v-if="loading">Impersonating user...</div>
        <div v-else-if="error" class="error">{{ error }}</div>
        <div v-else class="success">Impersonation successful. Redirecting...</div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
import EnvProvider from "jvjr-docker-env";

export default {
    setup() {
        const router = useRouter()
        const loading = ref(true)
        const error = ref(null)

        onMounted(async () => {
            const urlParams = new URLSearchParams(window.location.search)
            const token = urlParams.get('token')
            const signature = urlParams.get('signature')
            const expires = urlParams.get('expires')

            if (token && signature && expires) {
                try {
                    const adminBaseUrl = `${EnvProvider.value("ADMIN_BASE_URL")}`;
                    const response = await axios.get(`${adminBaseUrl}/api/impersonate/frontend`, {
                        params: { expires, token, signature }
                    })

                    localStorage.setItem('token', response.data.token)
                    setTimeout(() => router.replace({ name: "classSchedule" }), 2000)
                } catch (error) {
                    console.error('Impersonation error:', error)
                    error.value = 'Impersonation failed. Redirecting to class schedule...'
                    setTimeout(() => router.replace({ name: "classSchedule" }), 2000)
                }
            } else {
                error.value = 'Invalid impersonation parameters. Redirecting to class schedule...'
                setTimeout(() => router.replace({ name: "classSchedule" }), 2000)
            }
            loading.value = false
        })

        return { loading, error }
    }
}
</script>

<style scoped>
.error {
    color: red;
}
.success {
    color: green;
}
</style>